<template>
  <div class="tab-video">
    <div class="search-wrap">
      <el-form inline @submit.native.prevent>
        <template v-if="!batchOperation">
          <el-form-item>
            <el-input
              v-model="search.searchKey"
              v-debounce="[onSearch, 'input']"
              class="input"
              clearable
              prefix-icon="el-icon-search"
              placeholder="搜索视频名称"
              @clear="onSearch"
              @keydown.enter.native="onSearch"
            />
          </el-form-item>
          <el-form-item>
            <ksh-select
              v-model="search.isQueryChild"
              placeholder="我创建的素材"
              @change="onSearch"
            >
              <el-option
                v-for="item in orgSource"
                :key="item.isQueryChild"
                :label="item.title"
                :value="item.isQueryChild"
              />
            </ksh-select>
          </el-form-item>
          <el-form-item v-if="!search.isQueryChild">
            <div :class="{ 'select-complex': true }">
              <ksh-select
                v-model="search.categoryId"
                clearable
                placeholder="选择分类"
                class="select"
                @change="onSearch"
              >
                <el-option
                  v-for="item in optionsList"
                  :key="item.categoryId"
                  :label="item.title"
                  :value="item.categoryId"
                />
              </ksh-select>
              <div v-if="$auth('acadSaas.materialMgmt.cmVid')" class="right-btn" @click="handleParentDialog">
                <svg-icon name="manage-icon" />
              </div>
            </div>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item>
            <el-button type="info" plain @click="onBatchDel">
              批量删除
            </el-button>
          </el-form-item>
          <el-form-item v-if="!search.isQueryChild">
            <el-button type="info" plain @click="onSetMoreTag('isBulkShare')">批量分享</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="info" plain @click="onSetMoreTag('isBulkSetTag')">批量设置标签/关键信息</el-button>
          </el-form-item>
        </template>
      </el-form>
      <div>
        <ksh-button type="info" plain @click="showBatchOperation">{{ batchOperation? '取消': '' }}批量操作</ksh-button>
        <ksh-button
          v-if="$auth('acadSaas.materialMgmt.uploadVid')"
          icon="el-icon-plus"
          type="primary"
          @click="onUploadVideo"
        >
          上传视频
        </ksh-button>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      row-key="videoId"
      stripe
      style="border: 1px solid #f4f1f5; border-bottom: 0;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="batchOperation"
        reserve-selection
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        width="150px"
        align="center"
        prop="coverUrl"
        label="视频封面"
      >
        <template slot-scope="scoped">
          <div class="img-box">
            <el-image
              v-loading="scoped.row.status === 0"
              class="cover-img"
              :src="scoped.row.customCoverUrl||scoped.row.coverUrl"
              @click="onOpenDialog(scoped.row)"
            />
            <div v-if="scoped.row.status === 0" class="uploading">上传中...</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="视频标题" />
      <el-table-column
        align="center"
        label="产品标签"
      >
        <template slot-scope="scoped">
          <el-button
            v-if="!onGetTagName(scoped.row.tagInfos)"
            :disabled="scoped.row.status === 0"
            type="text"
            @click="onSetItemTag(scoped.row)"
          >
            前往设置 >
          </el-button>
          <span v-else>{{ onGetTagName(scoped.row.tagInfos) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="关键信息"
        show-overflow-tooltip
      >
        <template slot-scope="scoped">
          <el-button
            v-if="!onGetTagKnowledge(scoped.row.tagInfos)"
            :disabled="scoped.row.status === 0"
            type="text"
            @click="onSetItemTag(scoped.row)"
          >
            前往设置 >
          </el-button>
          <span v-else>{{ onGetTagKnowledge(scoped.row.tagInfos) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="120px"
        align="center"
        prop="duration"
        label="视频时长（min）"
      >
        <template slot-scope="scoped">
          {{ timeToMinute(scoped.row.duration) }}
        </template>
      </el-table-column>
      <el-table-column
        width="120px"
        align="center"
        prop="size"
        label="视频大小"
      >
        <template slot-scope="scoped">{{ (scoped.row.size / 1024 / 1024).toFixed(0) || 0 }}MB</template>
      </el-table-column>
      <el-table-column
        width="120px"
        align="center"
        prop="category.title"
        label="所属分类"
      />
      <el-table-column
        v-if="search.isQueryChild"
        width="120px"
        align="center"
        prop="createOrgName"
        label="创建组织"
      />
      <el-table-column
        width="120px"
        align="center"
        prop="dateTime"
        label="上传时间"
      />
      <el-table-column width="150px" align="center" label="操作">
        <template slot-scope="scoped">
          <el-button :disabled="scoped.row.status === 0" type="text" @click="onVideoViewer(scoped.row)">查看</el-button>
          <template v-if="!search.isQueryChild">
            <el-button
              v-if="$auth('acadSaas.materialMgmt.editVid')"
              :disabled="scoped.row.status === 0"
              type="text"
              @click="onEdit(scoped.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="$auth('acadSaas.materialMgmt.delVid')"
              :disabled="scoped.row.status === 0"
              type="text"
              :style="{color: scoped.row.status !== 0 && '#e8312f'}"
              @click="handelDelMessage(scoped.row)"
            >
              删除
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :hide-on-single-page="false"
      :page-sizes="pagination.sizes"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
    <video-viewer v-model="showVideoVewer" :video-url="currentSrc" />
    <UploadVideo
      :visible="isShowVisible"
      :item-obj="itemObj"
      @onClose="onCloseUpload"
      @onUpload="getDataList"
    />
    <ProductLabel
      :visible.sync="isShowTagModal"
      :disabled-ids="disabledIds"
      :selected-tag-list="selectedTagList"
      :options="{
        'SPREAD_DEPARTMENT_GROUP': {
          isShowTab: false
        },
        'CUSTOMIZE_TAG_GROUP': {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import ProductLabel from '@/bizComponents/ProductLabel'

import { getVideoList, deleteVideo } from '@/services/materialService.js'
import VideoViewer from './components/VideoViewer.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'TabVideo',
  components: {
    VideoViewer,
    ProductLabel
  },
  mixins: [paginationMixin],
  props: {
    optionsList: {
      type: Array,
      default: () => []
    },
    handleParentDialog: Function
  },
  data() {
    return {
      loading: false,
      isShowTagModal: false,
      selectedTagList: [],
      disabledIds: [],
      isBulkSetTag: false,
      isBulkShare: false,
      sceneType: 'video',
      search: {
        categoryId: '',
        searchKey: '',
        isQueryChild: false
      },
      tableData: [],
      itemObj: {},
      showVideoVewer: false,
      currentSrc: {},
      isShowVisible: false,
      batchOperation: false,
      multipleSelection: [],
      orgSource: [
        {isQueryChild: false, title: '我创建的素材'},
        {isQueryChild: true, title: '下级组织素材'}
      ]
    }
  },
  computed: {
    ...mapGetters(['tagGroupList'])
  },
  mounted() {
    this.getDataList()

  },
  activated() {
    this.getDataList()
  },
  methods: {
    onGetTagName(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list?.filter(v => v.groupCode === groupCode)?.map(v => v.tagName).join('-')
    },
    onGetTagKnowledge(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list?.filter(v => v.groupCode === groupCode)[0]?.knowledgePointInfos?.map(it => it.knowledgePointName).join('；')
    },
    onSubmitTag(newSelectList) {
      this.isShowTagModal = false
      const memberTagInfo = newSelectList.map(v => {
        return {
          userTagType: 10,
          tagId: v.tagId,
          groupId: v.groupId,
          tagName: v.tagName,
          knowledgePointIds: v.knowledgePointIds
        }
      })
      if (this.isBulkShare) {
        this.onShareList(memberTagInfo)
      } else if (this.isBulkSetTag) {
        this.onSetTag(memberTagInfo)
      }
    },
    onShareList(memberTagInfo) {
      const data = {
        isShareOrCancel: true,
        resourceIds: this.multipleSelection.map(it => it.videoId),
        resourceType: 0,
        memberTagInfo
      }
      this.$axios.post(this.$API.cancelMaterialShare, data).then(() => {
        this.$message.success('分享成功')
        this.batchOperation = false
        this.getDataList()
      }, rea => this.$message.error(rea.message))
    },
    onSetTag(memberTagInfo) {
      const data = {
        itemIds: this.multipleSelection.map(it => it.videoId),
        memberTagInfo
      }
      this.$axios.post(this.$API.batchSetTag, data).then(() => {
        this.$message.success('设置成功')
        this.batchOperation = false
        this.getDataList()
      }, rea => this.$message.error(rea.message))
    },
    onResetValve(bulkType) {
      this.isBulkSetTag = bulkType === 'isBulkSetTag'
      this.isBulkShare = bulkType === 'isBulkShare'
    },
    onOpenTagModal(bulkType) {
      if (!this.multipleSelection.length) return this.$message.error('请选择视频')
      this.isShowTagModal = true
      this.onResetValve(bulkType)
    },
    onSetMoreTag(bulkType) {
      this.disabledIds = []
      this.selectedTagList = []
      this.onOpenTagModal(bulkType)
    },
    onSetItemTag(item) {
      const {tagInfos = []} = item
      this.disabledIds = tagInfos ? tagInfos?.map(it => it.tagId) : []
      this.selectedTagList = item.tagInfos || []
      this.toggleSelection([item])
      this.onOpenTagModal('isBulkSetTag')
    },
    // 获取列表数据
    async getDataList() {
      this.loading = true
      try {
        const params = this.getParams(this.search)
        const { data: {totalCount, data} } = await getVideoList(params)
        this.tableData = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 上传视频
    onUploadVideo() {
      this.itemObj = {}
      this.isShowVisible = true
    },
    onCloseUpload() {
      this.isShowVisible = false
      this.itemObj = {}
    },
    onConfirmVideo() {
      this.getDataList()
    },
    // 编辑
    onEdit(item) {
      this.itemObj = JSON.parse(JSON.stringify(item))
      this.isShowVisible = true
    },
    handelDelMessage(item) {
      this.$MessageDialog({
        title: '删除',
        message: !item.isRealCanDel ? '确认删除该素材么？删除后不可再被引用,' : '确认删除该素材么？一经删除，不可恢复',
        newLineMessage: !item.isRealCanDel ? '您也可以在个人中心回收站重新找回' : ''
      }).then(() => {
        this.onDel(item.videoId)
      })
    },
    // 删除
    async onDel(videoIds) {
      try {
        this.loading = true
        await deleteVideo({ videoIds })
        await this.getDataList()
        this.loading = false
        this.$message.success('删除成功')
        this.toggleSelection()
      } catch (error) {
        this.loading = false
        this.$message({
          message: error.message,
          type: 'error'
        })
      }
    },
    // 批量删除
    onBatchDel() {
      if (this.multipleSelection.length !== 0) {
        let cannotRecover = 0
        let canRecover = 0
        this.multipleSelection.map(item => {
          if (item.isRealCanDel) {
            cannotRecover++
          } else {
            canRecover++
          }
        })
        this.$MessageDialog({
          title: '批量删除',
          message: `<div style="margin: 20px;"><div>确定要删除该批素材？${canRecover}个素材将会被放进回收站，</div>${cannotRecover}个素材将会直接删除</div>`
        }).then(() => {
          const ids = this.multipleSelection.map(item => item.videoId).join(',')
          this.onDel(ids)
        })
      } else {
        this.$message.error('请先选择要操作的数据项')
      }
    },
    showBatchOperation() {
      this.batchOperation = !this.batchOperation
      this.toggleSelection()
    },
    // 预览视频
    onVideoViewer(row) {
      if (row.aiParseStatus === 1) {
        const url = this.$router.resolve({
          path: '/sub-app/common-page/ai-parse',
          query: {
            id: row.videoId
          }
        })
        window.open(url.href, '_blank')
      } else {
        this.onOpenDialog(row)
      }
    },
    onOpenDialog(row) {
      if (row.status === 0) return
      const {playUrl = []} = row
      if (!playUrl) {
        this.$message.error('当前视频没有播放地址')
        return
      }
      this.showVideoVewer = true
      this.currentSrc = {
        mp4: {
          hd: this.findUrl('.mp4', 'hd', playUrl),
          sd: this.findUrl('.mp4', 'sd', playUrl),
          ld: this.findUrl('.mp4', 'ld', playUrl),
          fd: this.findUrl('.mp4', 'fd', playUrl)
        },
        hls: {
          hd: this.findUrl('.m3u8', 'hd', playUrl),
          sd: this.findUrl('.m3u8', 'sd', playUrl),
          ld: this.findUrl('.m3u8', 'ld', playUrl),
          fd: this.findUrl('.m3u8', 'fd', playUrl)
        }
      }
    },
    // 搜索
    onSearch() {
      this.pagination.pageNo = 1
      if (this.search.isQueryChild) {
        this.search.categoryId = ''
      }
      this.getDataList()
    },
    // 回显表格数据
    toggleSelection(rows) {
      this.$refs.multipleTable.clearSelection()
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 秒转换分钟00:00:00格式
    timeToMinute(times) {
      let t
      if (times > -1) {
        const hour = Math.floor(times / 3600)
        const min = Math.floor(times / 60) % 60
        const sec = times % 60
        t = hour < 10 ? `0${hour}:` : `${hour}:`

        if (min < 10) {
          t += '0'
        }
        t += `${min}:`
        if (sec < 10) {
          t += '0'
        }
        t += sec.toFixed(2)
      }
      t = t.substring(0, t.length - 3)
      return t
    },
    findUrl(type, clarity, arr) {
      let url = ''
      arr = arr || []
      arr.some(item => {
        if (item.indexOf(type) !== -1 && item.indexOf(clarity) !== -1) {
          url = item
          return true
        }
        return false
      })
      return url
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tab-video {
  .search-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .input {
      ::v-deep .el-input__inner {
        border-radius: 20px;
      }
    }
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        > span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          > span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .select-complex {
      display: flex;
      box-sizing: border-box;
      border: 1px solid #dcdfe6;
      background-color: #fbfbfb;
      margin: 1px;
      transition: all 0.3s;
      &:hover {
        border-color: #c0c4cb;
      }
      &:focus {
        border-color: $color-primary;
      }
      ::v-deep .select {
        line-height: 26px;
        .el-input {
          .el-input__inner {
            height: 26px;
            line-height: 26px;
            background-color: transparent;
            border: 1px solid transparent;
            border-right: none;
          }
        }
      }
      .right-btn {
        width: 28px;
        line-height: 26px;
        text-align: center;
        color: #a3a3a3;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 12px;
          background-color: #e1e1e1;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
  }
  ::v-deep .cover-img {
    width: 100px;
    height: 60px;
    img {
      width: 100px;
      height: 60px;
      object-fit: cover;
    }
  }
  .pagination {
    margin-top: 10px;
  }
}
.img-box {
  position: relative;
  z-index: 2000;
  .uploading {
    position: absolute;
    top: calc(50% - 14px);
    left: calc(50% - 24px);
    z-index: inherit;
    font-weight: bold;
    color: #5a5a5a;
  }
}
</style>
