<template>
  <el-dialog
    :visible.sync="dialogVisible"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="upload">
      <div class="item upload-pdf" @click="handleComponentSwitch('TabKnowledgeUploadPDF')">
        <img :src="require('@/assets/material/PDF.png')" alt="">
        <span>上传PDF</span>
      </div>
      <div class="item upload-ppt" @click="handleComponentSwitch('TabKnowledgeUploadPPT')">
        <img :src="require('@/assets/material/PPT.png')" alt="">
        <span>上传PPT</span>
      </div>
      <div class="item upload-word" @click="() => {dialogVisible = false, $router.push({name: 'KnowledgeCreate'})}">
        <img :src="require('@/assets/material/WORD.png')" alt="">
        <span>自建图文</span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <ksh-button @click="dialogVisible = false">取 消</ksh-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'TabKnowledgeUpload',
  props: {
    value: Boolean
  },
  data() {
    return {

    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {

  },
  methods: {
    handleComponentSwitch(name) {
      this.$emit('handle-show-component', name)
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.upload {
  width: 330px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item {
    padding: 2px 15px;
    border-radius: 2px;
    transition: all 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #e6effb;
    }
  }
}
</style>
