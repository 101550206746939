<template>
  <page-main class="customize-page-main">
    <el-tabs v-model="activeName" class="tabs">
      <el-tab-pane label="视频" name="video">
        <TabVideo v-if="activeName === 'video'" :handle-parent-dialog="handleParentDialog" :options-list="optionsList" />
      </el-tab-pane>
      <el-tab-pane label="文献资料" name="knowledge">
        <TabKnowledge v-if="activeName === 'knowledge'" :handle-parent-dialog="handleParentDialog" :options-list="optionsList" />
      </el-tab-pane>
      <el-tab-pane label="题目" name="exam">
        <TabExam
          v-if="activeName === 'exam'"
          :handle-parent-dialog="handleParentDialog"
          :options-list="optionsList"
        />
      </el-tab-pane>
    </el-tabs>
    <classification-management v-model="visible" :table-data.sync="optionsList" :category-type="activeName" />
  </page-main>
</template>

<script>
import TabVideo from './TabVideo.vue'
import TabKnowledge from './TabKnowledge.vue'
import TabExam from './TabExam.vue'
import ClassificationManagement from './components/ClassificationManagement.vue'

export default {
  name: 'List',
  components: {
    TabVideo,
    TabKnowledge,
    TabExam,
    ClassificationManagement
  },
  data() {
    return {
      activeName: 'video',
      visible: false,
      optionsList: []
    }
  },
  mounted() {
    this.activeName = this.$route.query.activeName || 'video'
  },
  methods: {
    handleParentDialog() {
      this.visible = true
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tabs {
  ::v-deep .el-tabs__header {
    margin-bottom: 0;
    .el-tabs__nav-wrap {
      overflow: inherit;
      &::after {
        width: calc(100% + 2 * #{$g-main-wrap-padding});
        left: -$g-main-wrap-padding;
        height: 1px;
      }
    }
  }
}
</style>
