<template>
  <div class="tab-knowledge-upload-PDF">
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      label-width="120px"
    >
      <el-form-item label="文件名称:" prop="title">
        <el-input
          v-model="form.title"
          maxlength="40"
          show-word-limit
          class="input"
          placeholder="输入文件名称"
        />
      </el-form-item>
      <el-form-item prop="files" label="上传PDF文件:">
        <div>文件大小不超过20M</div>
        <div class="upload-label">
          <ksh-button v-if="form.files.length" type="text" @click="reUpload">重新上传</ksh-button>
        </div>
        <file-upload
          class="file-upload"
          :size="20"
          :max="1"
          :files="form.files"
          :action="API.fileUpload"
          :ext="['pdf']"
          :on-success="onSuccess"
        >
          <div class="upload-wrap">
            <img :src="require('@/assets/material/upload-pdf.png')" alt="">
            <span>选择文件</span>
          </div>
        </file-upload>
      </el-form-item>
      <el-form-item label="标签/关键信息:" prop="memberTagInfo">
        <div class="scene-form-content">
          <ProductLabelKnowledge :selected-tag-list="tagList" :disabled-ids="disabledIds" @change="onTagChange" />
          <div>
            <el-button plain type="info" @click="onOpenTagModal">添加标签<svg-icon name="el-icon-plus" /></el-button>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="选择分类:">
        <el-select v-model="form.categoryId" clearable placeholder="选择分类">
          <el-option
            v-for="item in optionsList"
            :key="item.categoryId"
            :label="item.title"
            :value="item.categoryId"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import API from '@/apis/api-types.js'
import { createPDF } from '@/services/materialService.js'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'
export default {
  name: 'TabKnowledgeUploadPDF',
  components: {
    ProductLabelKnowledge
  },
  props: {
    optionsList: {
      type: Array,
      default: () => []
    },
    selectedTagList: {
      type: Array,
      default: () => []
    },
    disabledIds: {
      type: Array,
      default: () => []
    }

  },
  data() {
    return {
      form: {
        title: '',
        fileKey: '',
        categoryId: '',
        pptKey: '',
        files: [],
        memberTagInfo: []
      },
      formRules: {
        title: [{ required: true, message: '请输入标题', target: 'blur' }],
        files: [{ type: 'array', required: true, message: '请上传文件', target: 'blur' }],
        memberTagInfo: [{ type: 'array', required: true, message: '请选择标签/关键信息', target: 'blur' }]
      },
      API
    }
  },
  computed: {
    tagList: {
      get() {
        const arr = this.selectedTagList?.map(v => {
          return {
            userTagType: 14,
            tagId: v.tagId,
            groupId: v.groupId,
            tagName: v.tagName,
            knowledgePointIds: v.knowledgePointIds
          }
        })
        this.$set(this.form, 'memberTagInfo', arr)
        return [...this.selectedTagList]
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  mounted() {},
  methods: {
    onOpenTagModal() {
      this.$emit('selectTag', true)
    },
    onTagChange(tagList) {
      this.tagList = tagList
    },
    async onSubmit() {
      try {
        const params = Object.assign({}, this.form)
        params.fileKey = params.files[0].response.data.fileKey
        delete params.files
        const res = await createPDF(params)
        this.$message.success('保存成功')
        Promise.resolve(res)
      } catch (error) {
        console.log(error)
        this.$message.error(error.message)
        Promise.reject(error)
      }
    },
    reUpload() {
      this.form.files = []
      document.querySelector('.file-upload>.el-upload').click()
    },
    onSuccess(res, file) {
      this.form.files = [file]
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tab-knowledge-upload-PDF {
  .upload-label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .upload-wrap {
    width: 200px;
    height: 113px;
    background: #f5f5f5;
    border: 1px solid #e1e1e1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    img {
      width: 34px;
      // height: 38px;
    }
    &:hover {
      border-color: $color-primary;
    }
  }
  ::v-deep .input {
    width: 250px;
    .el-input__inner {
      border-radius: 0;
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}
.scene-form-content {
  flex: 1;
}
</style>
