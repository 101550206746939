<template>
  <div class="operation-confirm-pop-box">
    <el-dialog :visible.sync="visible" width="500px" :close-on-click-modal="false" :show-close="false" top="35vh">
      <slot name="title" slot="title">
        <div class="title">{{ title }}</div>
      </slot>
      <div class="col272e40" style="text-align:center;">{{ message }}</div>
      <div slot="footer" class="flex-center mgn-t20">
        <el-button @click="onClose">{{ cancelFont }}</el-button>
        <el-button :loading="btnLoading" type="primary" @click="onSubmit">{{ confirmFont }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'OperationConfirmPop',
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    cancelFont: {
      type: String,
      default: '取消',
    },
    confirmFont: {
      type: String,
      default: '确定',
    },
  },
  data() {
    return {
      // -------------------- submit ----------------
      btnLoading: false,
    }
  },
  methods: {
    onClose() {
      this.$emit('onClose', false)
    },
    onSubmit() {
      this.$emit('onSubmit', this.id)
    },
  },
}
</script>
<style>
.operation-confirm-pop-box .el-dialog {
  width: 560px;
}
</style>
<style lang="scss" scoped>
.operation-confirm-pop-box {
  :deep(.el-dialog__body) {
    padding-top: 40px !important;
  }
}
</style>
