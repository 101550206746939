<template>
  <div class="tab-knowledge-preview">
    <page-main>
      <div class="title">{{ knowledgeDetail.title }}</div>
      <div class="author">{{ knowledgeDetail.author }}</div>
      <div v-safe-html="knowledgeDetail.content" class="context" />
    </page-main>
  </div>
</template>

<script>
import { getArticleDetail } from '@/services/materialService'
export default {
  name: 'TabKnowledgePreview',
  data() {
    return {
      knowledgeDetail: {}
    }
  },
  mounted() {
    this.getData(this.$route.params.id)
  },
  methods: {
    async getData(knowledgeId) {
      try {
        const res = await getArticleDetail({ knowledgeId })
        this.knowledgeDetail = res.data
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tab-knowledge-preview {
  min-height: 100%;
  background-color: #eff0f2;
  .page-main {
    max-width: 1200px;
    min-height: 100vh;
    margin: 0 auto;
    .title {
      font-size: 24px;
      line-height: 1.4;
      font-weight: 500;
      padding: 15px 0;
    }
    .author {
      padding-bottom: 15px;
    }
    ::v-deep .context {
      width: 100%;
      img,
      p,
      span {
        max-width: 100% !important;
      }
    }
  }
}
</style>
