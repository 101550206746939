import { render, staticRenderFns } from "./TabKnowledgeUpload.vue?vue&type=template&id=562ced28&scoped=true"
import script from "./TabKnowledgeUpload.vue?vue&type=script&lang=js"
export * from "./TabKnowledgeUpload.vue?vue&type=script&lang=js"
import style0 from "./TabKnowledgeUpload.vue?vue&type=style&index=0&id=562ced28&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "562ced28",
  null
  
)

export default component.exports