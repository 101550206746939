<template>
  <div class="tab-knowledgeEdit">
    <page-main back title>
      <el-form
        ref="form"
        v-loading="loading"
        label-width="120px"
        size="small"
        class="customize-form cs-in-bold-title"
        :rules="formRules"
        style="width: 900px;"
        :model="form"
      >
        <el-form-item prop="title" label="文件名称:">
          <el-input
            v-model.trim="form.title"
            :maxlength="40"
            show-word-limit
            class="title-input"
            placeholder="请在这输入文献名称"
          />
        </el-form-item>
        <el-form-item prop="author" label="作者名称:">
          <el-input
            v-model.trim="form.author"
            placeholder="请在这输入作者名称"
            :maxlength="40"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="文献内容:">
          <RichEditor
            v-if="$route.query.id && showEditor || !$route.query.id"
            v-model="form.content"
            placeholder="请输入文献内容"
          />
        </el-form-item>
        <el-form-item label="标签/关键信息:" prop="memberTagInfo">
          <div class="tag-wrapper">
            <ProductLabelKnowledge :selected-tag-list="selectedTagList" :disabled-ids="disabledIds" @change="onTagChange" />
            <el-button plain type="info" @click="onOpenTagModal">添加标签<svg-icon name="el-icon-plus" /></el-button>
          </div>
        </el-form-item>
        <el-form-item label="选择分类:">
          <ksh-select v-model="form.categoryId" clearable>
            <el-option
              v-for="item in optionsList"
              :key="item.categoryId"
              :label="item.title"
              :value="item.categoryId"
            />
          </ksh-select>
          <el-button
            type="info"
            plain
            style="margin-left: 10px;"
            @click="dialog = true"
          >
            管理分类
          </el-button>
        </el-form-item>
      </el-form>
    </page-main>
    <fixed-action-bar>
      <ksh-button
        v-if="$route.query.from !== 'addArticleModal'"
        plain
        @click="isShowOperationConfirmPop = true"
      >
        取消
      </ksh-button>
      <ksh-button type="primary" :loading="loading" @click="onSubmit">保存</ksh-button>
    </fixed-action-bar>
    <ClassificationManagement v-model="dialog" category-type="knowledge" :table-data.sync="optionsList" />
    <OperationConfirmPop
      :visible="isShowOperationConfirmPop"
      message="当前页面未编辑完成，您确定要离开当前编辑页面吗？"
      title="操作确认"
      cancel-font="继续编辑"
      confirm-font="确定离开"
      @onClose="isShowOperationConfirmPop = false"
      @onSubmit="goBack"
    >
      <div slot="title">确认操作</div>
    </OperationConfirmPop>
    <ProductLabel
      :visible.sync="showTagModal"
      :disabled-ids="disabledIds"
      :selected-tag-list="selectedTagList"
      :options="{
        'SPREAD_DEPARTMENT_GROUP': {
          isShowTab: false
        },
        'CUSTOMIZE_TAG_GROUP': {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>

<script>
// import Editor from '@/baseComponents/Editor.vue'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import ClassificationManagement from './components/ClassificationManagement'
import ProductLabel from '@/bizComponents/ProductLabel'
import ProductLabelKnowledge from '@/bizComponents/ProductLabelKnowledge'

import { getArticleDetail, updateArticle, createArticle } from '@/services/materialService'
export default {
  name: 'TabKnowledgeEdit',
  components: {
    // Editor,
    ClassificationManagement,
    OperationConfirmPop,
    ProductLabel,
    ProductLabelKnowledge
  },
  data() {
    return {
      showTagModal: false,
      selectedTagList: [],
      disabledIds: [],
      dialog: false,
      loading: false,
      showEditor: false,
      isShowOperationConfirmPop: false,
      form: {
        title: '',
        knowledgeId: '',
        content: '',
        author: '',
        categoryId: '',
        memberTagInfo: []
      },
      formRules: {
        title: [{required: true, message: '请输入标题', trigger: 'blur'}],
        author: [{required: true, message: '请输入作者', trigger: 'blur'}],
        memberTagInfo: [{ type: 'array', required: true, message: '请选择标签/关键信息', target: 'blur' }]
      },
      optionsList: []
    }
  },
  mounted() {
    if (this.$route.query.id) {
      // 编辑
      this.getDetail(this.$route.query.id)
    }
  },
  methods: {
    onSubmitTag(selectedList) {
      this.showTagModal = false
      this.selectedTagList = [...selectedList]
      const arr = this.selectedTagList?.map(v => {
        return {
          userTagType: 14,
          tagId: v.tagId,
          groupId: v.groupId,
          tagName: v.tagName,
          knowledgePointIds: v.knowledgePointIds
        }
      })
      this.$set(this.form, 'memberTagInfo', arr)
    },
    onTagChange(tagList) {
      this.selectedTagList = tagList
    },
    onOpenTagModal() {
      this.showTagModal = true
    },
    // 获取详情
    async getDetail(knowledgeId) {
      try {
        const { data } = await getArticleDetail({ knowledgeId })
        this.form = {
          ...this.form,
          ...data
        }
        this.form.categoryId = data.category?.categoryId
        this.selectedTagList = data.tagInfos || []
        this.disabledIds = data.tagInfos?.map(v => v.tagId)
        this.showEditor = true
      } catch (error) {
        this.showEditor = true
        console.log('[ error ] >', error)
      }
    },
    // 保存
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            if (this.$route.query.id) {
              await updateArticle(this.form)
            } else {
              await createArticle(this.form)
            }
            this.$message({
              type: 'success',
              message: '保存成功'
            })
            this.loading = false
            this.goBack()
          } catch (error) {
            this.loading = false
            this.$message({
              type: 'error',
              message: error.message
            })
          }
        } else {
          return false
        }
      })
    },
    goBack() {
      this.$router.push('/material/list')
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tab-knowledgeEdit {
  .tag-wrapper {
    // display: flex;
    // flex-wrap: wrap;
    // gap: 10px;
  }
}
</style>
