import { render, staticRenderFns } from "./ClassificationManagement.vue?vue&type=template&id=faaa73c2&scoped=true"
import script from "./ClassificationManagement.vue?vue&type=script&lang=js"
export * from "./ClassificationManagement.vue?vue&type=script&lang=js"
import style0 from "./ClassificationManagement.vue?vue&type=style&index=0&id=faaa73c2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "faaa73c2",
  null
  
)

export default component.exports